import React, {ReactElement} from 'react';

import {NEU_PRO_KIEZEL_PAY_APP_ID} from '../neu_pro/NeuProInfo';
import OpenConnectIqButton from './OpenConnectIqButton';

type Props = {
  buttonElementName: string;
};

export default function DownloadParagraph({
  buttonElementName,
}: Props): ReactElement {
  const price = '$2.99';
  // const price = usePrice();

  return (
    <>
      <p className="download-paragraph">
        <OpenConnectIqButton
          appId={NEU_PRO_KIEZEL_PAY_APP_ID}
          elementName={buttonElementName}
          title={`Try Free Now`}
        />
        <br />
        Free for 3 days—no credit card needed until the trial ends , {price} to
        continue after.
      </p>
    </>
  );
}

// This is the old code for the version via Garmin Pay:
//
// export default function DownloadParagraph({
//   buttonElementName,
// }: Props): ReactElement {
//   const price = usePrice();

//   return (
//     <>
//       <p className="download-paragraph">
//         <OpenConnectIqButton
//           appId={NEU_PRO_APP_ID}
//           elementName={buttonElementName}
//           title={`Download for ${price}`}
//         />
//         <br />
//         Not for you? <br />
//         Claim a full refund within 2 days.
//       </p>
//       <CountrySelector />
//     </>
//   );
// }
