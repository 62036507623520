import React, {ReactElement, useContext, useRef, useState} from 'react';

import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';

import {getReferenceDistance} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import {ViewPreferencesContext} from '../utils/ViewPreferencesContext';
import NeuProWatchFace, {NeuProWatchFaceProps} from '../watch_face/NeuPro';
import './css/SectionStyle.css';

type Props = {};

export default function SectionStyle({}: Props): ReactElement {
  const viewPrefsCtx = useContext(ViewPreferencesContext);
  const containerRef = useRef(null);
  const galleryDivRef = useRef<HTMLDivElement>(null);

  const zoom = [2.5, 3.5, 2.5, 3.5];

  useGSAP(
    () => {
      if (viewPrefsCtx.noAutoAnimations) {
        return;
      }

      const photosInternal = gsap.utils.toArray<Element>(
        '.Photo-container-internal',
      );
      const photos = gsap.utils.toArray<Element>('.Photo-container');

      const scrollDistance = photos.length * getReferenceDistance();

      const headerHeight =
        document.querySelector<HTMLElement>('header')?.offsetHeight ?? 0;

      const scrollTrigger: ScrollTrigger.Vars = {
        anticipatePin: 1,
        end: `+=${scrollDistance}`,
        // markers: true,
        pin: true,
        scrub: 0.1,
        start: `+=0 +=${headerHeight}px`,
        trigger: '.Photo-gallery-section',
      };

      let tl = gsap.timeline({scrollTrigger});

      const TRANSITION_DURATION = 50;
      const ZOOM_DURATION = 50;
      const SINGLE_PHOTO_DURATION = TRANSITION_DURATION + ZOOM_DURATION;

      for (let i = 0; i < photos.length; ++i) {
        tl = tl.to(
          photosInternal[i],
          {
            duration: ZOOM_DURATION,
            ease: 'power2.inOut',
            scale: zoom[i] ?? 2.5,
          },
          SINGLE_PHOTO_DURATION * i,
        );
        if (i !== photos.length - 1) {
          tl = tl.to(
            '.Photo-gallery-internal',
            {
              duration: TRANSITION_DURATION,
              ease: 'power2.inOut',
              xPercent: (-100 * (i + 1)) / photos.length,
            },
            SINGLE_PHOTO_DURATION * i + ZOOM_DURATION,
          );
        }
      }
    },
    {scope: containerRef},
  );

  const [scrollButtonsState, setScrollButtonsState] = useState({
    next: true,
    prev: false,
  });

  const scrollTheGallery = (direction: number) => {
    const el = galleryDivRef.current;
    if (el) {
      el.scrollTo({
        behavior: 'smooth',
        left: el.scrollLeft + direction * el.offsetWidth,
      });
    }
  };

  const galleryScrollHandler = () => {
    const el = galleryDivRef.current;
    const galleryInternal = document.querySelector<HTMLElement>(
      '.Photo-gallery-internal',
    );
    const galleryWidth = galleryInternal?.offsetWidth ?? 10000;

    if (el) {
      setScrollButtonsState(p => {
        const newState: typeof p = {
          next:
            el.scrollLeft + el.offsetWidth < galleryWidth - el.offsetWidth / 2,
          prev: el.scrollLeft > el.offsetWidth / 2,
        };

        return p.next !== newState.next || p.prev !== newState.prev
          ? newState
          : p;
      });
    }
  };

  return (
    <div ref={containerRef}>
      <ViewTracker sectionId="section_style">
        <section className="Photo-gallery-section section-with-image">
          <div className="section-title-cell">
            <h2>Thoughtfully designed</h2>
            <h3>Style that fits everyone</h3>
          </div>
          <div
            className="section-image-cell Inline-size-container"
            style={{position: 'relative'}}>
            <div
              className={`section-image-clip Photo-gallery`}
              ref={galleryDivRef}
              onScroll={galleryScrollHandler}>
              <div className="Photo-gallery-internal">
                <PhotoItem
                  dataFieldBottom="heart_rate"
                  dataFieldLeft="notification_count"
                  dataFieldRight="weekly_bike_distance"
                  dataFieldTop="calendar_event"
                  hoursOverride={10}
                  minutesOverride={32}
                  zoomScale={zoom[0]}
                  hideAmPm
                />
                <PhotoItem
                  dataFieldBottom="heart_rate"
                  dataFieldLeft="steps"
                  dataFieldRight="vo2_max_bike"
                  dataFieldTop="current_temperature"
                  hoursOverride={16}
                  minutesOverride={8}
                  zoomScale={zoom[1]}
                  hideAmPm
                />
                {viewPrefsCtx.noAutoAnimations ? (
                  <>
                    <PhotoItem
                      dataFieldBottom="sunrise_sunset"
                      dataFieldLeft="floors_climbed"
                      dataFieldRight="heart_rate"
                      dataFieldTop="weekly_run_distance"
                      hoursOverride={14}
                      minutesOverride={29}
                      zoomScale={zoom[2]}
                    />
                    <PhotoItem
                      dataFieldBottom="heart_rate"
                      dataFieldLeft="intensity_minutes"
                      dataFieldRight="recovery_time"
                      dataFieldTop="stress"
                      hoursOverride={18}
                      minutesOverride={41}
                      zoomScale={zoom[3]}
                      hideAmPm
                    />
                  </>
                ) : null}
              </div>
            </div>
            <button
              className={`Photo-prev-button Photo-round-button`}
              disabled={!scrollButtonsState.prev}
              onClick={() => scrollTheGallery(-1)}
            />
            <button
              className={`Photo-next-button Photo-round-button`}
              disabled={!scrollButtonsState.next}
              onClick={() => scrollTheGallery(1)}
            />
          </div>
          <div className="section-text-cell">
            <ul>
              <li>
                A clean, modern design with <b>attention to detail</b>
              </li>
              <li>
                <b>Typography</b> optimized for clarity and ease of use
              </li>
              <li>
                Thoroughly tested to ensure <b>data fields never overlap</b>
              </li>
              <li>
                <b>Great contrast and readability</b>, even on MIP displays
              </li>
            </ul>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}

type PhotoItemProps = NeuProWatchFaceProps & {
  zoomScale: number;
};

function PhotoItem(props: PhotoItemProps): ReactElement {
  const {zoomScale, ...wfProps} = props;

  const [zoomedIn, setZoomedIn] = useState(false);

  const zoomButtonRef = useRef<HTMLButtonElement>(null);

  // The image urls and the watch face transforms are specified in CSS
  return (
    <div className="Photo-container">
      <div
        className="Photo-container-internal"
        style={{transform: zoomedIn ? `scale(${zoomScale})` : 'scale(1)'}}>
        <div className="Abs-fill-parent Watch-face-placeholder">
          <NeuProWatchFace {...wfProps} />
        </div>
        <img className="Photo-image" />
      </div>
      <button
        className={`Photo-zoom-button Photo-round-button`}
        ref={zoomButtonRef}
        onClick={() =>
          setZoomedIn(p => {
            zoomButtonRef.current?.classList.toggle('zoomed-in', !p);
            return !p;
          })
        }
      />
    </div>
  );
}
