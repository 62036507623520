import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import {ScrollTrigger} from 'gsap/ScrollTrigger';

import {logAnalyticsEvent} from '../utils/AnalyticsUtils';
import useNavigateToConnectIq from '../utils/useNavigateToConnectIq';
import './css/ConnectIqNavDialog.css';

export function ConnectIqNavDialog(): ReactElement {
  const ctx = useContext(ConnectIqNavDialogContext);

  const [watchDevice, setWatchDevice] = useState('');
  const deviceSupportedStatus = DEVICES.find(
    d => d.id === watchDevice,
  )?.supported;

  const navigateToConnectIq = useNavigateToConnectIq({
    appId: ctx.dialogAppId ?? '',
    elementName: ctx.dialogEntryPoint ?? 'none',
  });

  function closeConnectIqNavDialog(): void {
    logAnalyticsEvent('close_ciq_dialog');
    toggleConnectIqNavDialog(false);
  }

  const onDeviceSelected = (devId: string) => {
    logAnalyticsEvent('device_selected', {device_id: devId});
    setWatchDevice(devId);
  };

  let appParagraph: ReactNode;

  // if (isIOS || isAndroid) {
  //   const os = isIOS ? 'iOS' : 'Android';
  //   appParagraph = (
  //     <p>
  //       <small>
  //         If you have the Connect IQ app installed on your {os} device, it will
  //         open automatically. Otherwise, you&apos;ll be taken to{' '}
  //         <u>apps.garmin.com</u>.
  //       </small>
  //     </p>
  //   );
  // }

  let maybeBack = '';
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const utm_source = searchParams.get('utm_source');
    if (utm_source === 'connect_iq') {
      maybeBack = ' back';
    }
  } catch (e) {
    console.error("Couldn't get utm_source");
  }

  return (
    <>
      <div className="connect-iq-nav-dialog-positioning">
        <div
          className="connect-iq-nav-dialog-overlay"
          onClick={closeConnectIqNavDialog}
        />
        <div className="connect-iq-nav-dialog">
          <h3>
            Redirecting{maybeBack} to <b>Connect IQ Store</b>
          </h3>
          {/* <p>
            To finish your purchase, you&apos;ll be redirected{maybeBack} to
            Garmin&apos;s Connect IQ store.
          </p> */}
          <p>
            To start your free trial, you&apos;ll be redirected{maybeBack} to
            Garmin&apos;s Connect IQ store.
          </p>
          <p>
            <small>
              Connect IQ is Garmin&apos;s official platform for downloading
              custom watch faces directly to your device.
            </small>
          </p>
          {appParagraph}
          <p>
            <b>Choose your device</b>
            <br />
            <select
              className="required"
              onChange={e => onDeviceSelected(e.target.value)}>
              <option>Not selected</option>
              {DEVICES.map(d => (
                <option key={d.id} value={d.id}>
                  {d.title}
                </option>
              ))}
            </select>
          </p>
          {watchDevice ? (
            deviceSupportedStatus === 'garmin_no' ? (
              <p>Sorry, this watch model is not supported.</p>
            ) : deviceSupportedStatus === 'other_no' ? (
              <p>This watch face is exclusively for Garmin devices.</p>
            ) : null
          ) : null}
          <div className="connect-iq-nav-dialog-buttons">
            <button
              disabled={!watchDevice || deviceSupportedStatus !== 'yes'}
              onClick={() => navigateToConnectIq()}>
              Open Connect IQ
            </button>
            <button
              className="button-secondary"
              onClick={closeConnectIqNavDialog}>
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export function useOpenConnectIqNavDialog(
  appId: string,
  dialogEntryPoint: string,
): () => void {
  const ctx = useContext(ConnectIqNavDialogContext);

  return () => {
    ctx.updateDialogCtx(appId, dialogEntryPoint);
    toggleConnectIqNavDialog(true);
    logAnalyticsEvent('open_ciq_dialog');
  };
}

function toggleConnectIqNavDialog(open: boolean): void {
  const el = document.getElementsByClassName(
    'connect-iq-nav-dialog-positioning',
  )[0];

  if (!el) {
    console.error('Cookie Dialog element not found');
  }

  document.body.classList.toggle('noscroll', open);
  el.classList.toggle(OPEN_CLASSNAME, open);
  ScrollTrigger.refresh();
}

const OPEN_CLASSNAME = 'open';

export type ConnectIqNavDialogContextType = {
  dialogAppId: string | undefined;
  dialogEntryPoint: string | undefined;
  updateDialogCtx: (
    dialogAppId: string | undefined,
    dialogEntryPoint: string | undefined,
  ) => void;
};

export const ConnectIqNavDialogContext =
  createContext<ConnectIqNavDialogContextType>({
    dialogAppId: undefined,
    dialogEntryPoint: undefined,
    updateDialogCtx: () => {
      console.error('No ConnectIqNavDialogContext.Provider above');
      throw new Error('No ConnectIqNavDialogContext.Provider above');
    },
  });

type Props = {
  children: ReactNode;
};

export function ConnectIqNavDialogContextProvider({
  children,
}: Props): ReactElement {
  const [state, setState] = useState<
    Omit<ConnectIqNavDialogContextType, 'updateDialogCtx'>
  >({
    dialogAppId: undefined,
    dialogEntryPoint: undefined,
  });

  const ctxValue = useMemo((): ConnectIqNavDialogContextType => {
    return {
      ...state,
      updateDialogCtx(
        dialogAppId: string | undefined,
        dialogEntryPoint: string | undefined,
      ) {
        setState({dialogAppId, dialogEntryPoint});
      },
    };
  }, [state]);

  return (
    <ConnectIqNavDialogContext.Provider value={ctxValue}>
      {children}
    </ConnectIqNavDialogContext.Provider>
  );
}

type Supported = 'yes' | 'garmin_no' | 'other_no';
type DeviceDesc = {
  id: string;
  supported: Supported;
  title: string;
};

const DEVICES: DeviceDesc[] = [
  {id: 'apple', supported: 'other_no', title: 'Apple Watch'},
  {id: 'approach_s60', supported: 'garmin_no', title: 'Approach S60'},
  {id: 'approach_s62', supported: 'garmin_no', title: 'Approach S62'},
  {id: 'approach_s70', supported: 'yes', title: 'Approach S70'},
  {id: 'd2', supported: 'garmin_no', title: 'D2'},
  {id: 'd2_mach1', supported: 'yes', title: 'D2 Mach 1 / Mach 1 Pro'},
  {id: 'descent_g1', supported: 'garmin_no', title: 'Descent G1'},
  {id: 'descent_mk1', supported: 'garmin_no', title: 'Descent Mk1'},
  {id: 'descent_mk2', supported: 'garmin_no', title: 'Descent Mk2 / Mk2i'},
  {id: 'descent_mk3', supported: 'yes', title: 'Descent Mk3 / Mk3i'},
  {id: 'enduro', supported: 'garmin_no', title: 'Enduro'},
  {id: 'enduro_2', supported: 'yes', title: 'Enduro 2'},
  {id: 'enduro_3', supported: 'yes', title: 'Enduro 3'},
  {id: 'fire_boltt', supported: 'other_no', title: 'Fire Boltt watches'},
  {id: 'fitbit', supported: 'other_no', title: 'Fitbit watches'},
  {id: 'forerunner_45', supported: 'garmin_no', title: 'Forerunner 45'},
  {id: 'forerunner_55', supported: 'garmin_no', title: 'Forerunner 55'},
  {id: 'forerunner_165', supported: 'yes', title: 'Forerunner 165'},
  {id: 'forerunner_230', supported: 'garmin_no', title: 'Forerunner 230'},
  {id: 'forerunner_235', supported: 'garmin_no', title: 'Forerunner 235'},
  {id: 'forerunner_245', supported: 'garmin_no', title: 'Forerunner 245'},
  {id: 'forerunner_255', supported: 'yes', title: 'Forerunner 255 / 255s'},
  {id: 'forerunner_265', supported: 'yes', title: 'Forerunner 265 / 265s'},
  {id: 'forerunner_630', supported: 'garmin_no', title: 'Forerunner 630'},
  {id: 'forerunner_645', supported: 'garmin_no', title: 'Forerunner 645'},
  {id: 'forerunner_735', supported: 'garmin_no', title: 'Forerunner 735xt'},
  {id: 'forerunner_745', supported: 'garmin_no', title: 'Forerunner 745'},
  {id: 'forerunner_920', supported: 'garmin_no', title: 'Forerunner 920XT'},
  {id: 'forerunner_935', supported: 'garmin_no', title: 'Forerunner 935'},
  {id: 'forerunner_945', supported: 'garmin_no', title: 'Forerunner 945'},
  {id: 'forerunner_955', supported: 'yes', title: 'Forerunner 955'},
  {id: 'forerunner_965', supported: 'yes', title: 'Forerunner 965'},
  {id: 'garmin_swim', supported: 'garmin_no', title: 'Garmin Swim 2'},
  {id: 'huawei', supported: 'other_no', title: 'Huawei watches'},
  {id: 'instinct_2', supported: 'garmin_no', title: 'Instinct 2 / 2S / 2X'},
  {id: 'instinct_co', supported: 'garmin_no', title: 'Instinct Crossover'},
  {id: 'marq', supported: 'garmin_no', title: 'MARQ'},
  {id: 'marq_2', supported: 'yes', title: 'MARQ (Gen 2)'},
  {id: 'pixel', supported: 'other_no', title: 'Pixel Watch'},
  {id: 'galaxy', supported: 'other_no', title: 'Samsung Galaxy Watch'},
  {id: 'venu', supported: 'garmin_no', title: 'Venu'},
  {id: 'venu_2', supported: 'yes', title: 'Venu 2 / 2S / 2 Plus'},
  {id: 'venu_3', supported: 'yes', title: 'Venu 3 / 3S'},
  {id: 'venu_sq', supported: 'garmin_no', title: 'Venu Sq'},
  {id: 'venu_sq_2', supported: 'garmin_no', title: 'Venu Sq 2'},
  {id: 'epix', supported: 'garmin_no', title: 'epix'},
  {id: 'epix_2', supported: 'yes', title: 'epix (Gen 2)'},
  {id: 'epix_pro_2', supported: 'yes', title: 'epix Pro (Gen 2)'},
  {id: 'fenix_3', supported: 'garmin_no', title: 'fēnix 3'},
  {id: 'fenix_5', supported: 'garmin_no', title: 'fēnix 5 / 5 Plus'},
  {id: 'fenix_5s', supported: 'garmin_no', title: 'fēnix 5S / 5S Plus'},
  {id: 'fenix_5x', supported: 'garmin_no', title: 'fēnix 5X / 5X Plus'},
  {id: 'fenix_6', supported: 'garmin_no', title: 'fēnix 6 / 6S / 6X'},
  {id: 'fenix_6_pro', supported: 'garmin_no', title: 'fēnix 6 Pro'},
  {id: 'fenix_6s_pro', supported: 'garmin_no', title: 'fēnix 6S Pro'},
  {id: 'fenix_6x_pro', supported: 'garmin_no', title: 'fēnix 6X Pro'},
  {id: 'fenix_7', supported: 'yes', title: 'fēnix 7'},
  {id: 'fenix_8', supported: 'yes', title: 'fēnix 8'},
  {id: 'fenix_e', supported: 'yes', title: 'fēnix E'},
  {id: 'quatix_3', supported: 'garmin_no', title: 'quatix 3'},
  {id: 'quatix_5', supported: 'garmin_no', title: 'quatix 5'},
  {id: 'quatix_6', supported: 'garmin_no', title: 'quatix 6'},
  {id: 'quatix_7', supported: 'yes', title: 'quatix 7 / 7 Pro'},
  {id: 'tactix_7', supported: 'yes', title: 'tactix 7'},
  {
    id: 'tactix_cdb',
    supported: 'garmin_no',
    title: 'tactix Charlie/Delta/Bravo',
  },
  {id: 'vivoactive', supported: 'garmin_no', title: 'vívoactive'},
  {id: 'vivoactive_3', supported: 'garmin_no', title: 'vívoactive 3'},
  {id: 'vivoactive_4', supported: 'garmin_no', title: 'vívoactive 4 / 4S'},
  {id: 'vivoactive_5', supported: 'yes', title: 'vívoactive 5'},
  {id: 'other', supported: 'garmin_no', title: 'Other'},
];
