import {i18n} from '../i18n';

export function qweqwe() {}

export type TextFieldLength = 'short' | 'long';

export type TimeFormat = '12h' | '24h';

export function formatNumeric_percents_1_to_100(
  x: number,
  langOverride?: string,
): string {
  const val = x.toFixed(0);
  return i18n.t(
    `format:percents`,
    langOverride ? {lng: langOverride, val} : {val},
  );
}

export function formatNumeric_hours_1_to_100(
  x: number,
  length: TextFieldLength,
  langOverride?: string,
): string {
  // TODO: length
  const val = x.toFixed(0);
  return i18n.t(
    `format:hoursShort`,
    langOverride ? {lng: langOverride, val} : {val},
  );
}

export function formatNumeric_degrees_neg999_to_999(
  x: number,
  langOverride?: string,
): string {
  const val = x.toFixed(0);
  return i18n.t(
    `format:degrees`,
    langOverride ? {lng: langOverride, val} : {val},
  );
}

export function formatNumeric_days_1_to_999(
  x: number,
  langOverride?: string,
): string {
  const val = x.toFixed(0);
  return i18n.t(`format:days`, langOverride ? {lng: langOverride, val} : {val});
}

export function formatRange(
  min: string,
  max: string,
  langOverride?: string,
): string {
  return i18n.t(
    `format:range`,
    langOverride
      ? {end: max, lng: langOverride, start: min}
      : {end: max, start: 'min'},
  );
}

export function formatNumber_0_to_999k(
  x: number,
  length: TextFieldLength,
): string {
  if (x < 1000 || length === 'long') {
    return x.toFixed(0);
  } else if (x < 10000) {
    return (x / 1000.0).toFixed(1) + 'k';
  } else {
    return (x / 1000.0).toFixed(0) + 'k';
  }
}

export function formatFloat_0p01_to_999k(
  x: number,
  length: TextFieldLength,
): string {
  // TODO: trim trailing ".0"?
  if (x == 0) {
    return '0';
  } else if (x < 0.1) {
    return x.toFixed(2);
  } else if (x < 100) {
    return x.toFixed(1);
  } else if (length === 'long') {
    return x.toFixed(x < 1000 ? 1 : 0);
  } else {
    // short fields
    if (x < 1000) {
      return x.toFixed(0);
    } else if (x < 10000) {
      return (x / 1000.0).toFixed(1) + 'k';
    } else {
      return (x / 1000.0).toFixed(0) + 'k';
    }
  }
}

export function formatTimeH(timeFormat: TimeFormat, hours: number): string {
  return timeFormat === '24h'
    ? hours.toFixed(0).padStart(2, '0')
    : (((hours + 11) % 12) + 1).toFixed(0);
}

export function formatTimeHM(
  timeFormat: TimeFormat,
  hours: number,
  minutes: number,
): string {
  return `${formatTimeH(timeFormat, hours)}:${minutes.toFixed(0).padStart(2, '0')}`;
}
