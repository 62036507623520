import React, {ReactElement, ReactNode, createContext, useMemo} from 'react';

export type ViewPreferencesContextType = {
  animClass: 'no-auto-animation' | '';
  noAutoAnimations: boolean;
};

export const ViewPreferencesContext = createContext<ViewPreferencesContextType>(
  {
    animClass: '',
    noAutoAnimations: false,
  },
);

type Props = {
  children: ReactNode;
};

export function ViewPreferencesContextProvider({
  children,
}: Props): ReactElement {
  const ctxValue = useMemo((): ViewPreferencesContextType => {
    const searchParams = new URLSearchParams(window.location.search);
    const auto_anim = searchParams.get('auto_anim');
    const noAutoAnimations = auto_anim === 'off' ? true : false;

    return {
      animClass: noAutoAnimations ? 'no-auto-animation' : '',
      noAutoAnimations,
    };
  }, []);

  return (
    <ViewPreferencesContext.Provider value={ctxValue}>
      {children}
    </ViewPreferencesContext.Provider>
  );
}
