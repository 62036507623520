import React, {ReactElement, useContext, useRef} from 'react';

import {useMetaTimeline} from '../components/GsapUtils';
import ViewTracker from '../components/ViewTracker';
import {ViewPreferencesContext} from '../utils/ViewPreferencesContext';
import NeuProWatchFace from '../watch_face/NeuPro';
import './css/SectionIntroduce.css';
import './css/SectionLongPress.css';

type Props = {};

export default function SectionLongPress({}: Props): ReactElement {
  const viewPrefsCtx = useContext(ViewPreferencesContext);

  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: should depend on screen dimensions (width?)
  const DURATION_MOVE_IN = 200;
  const DURATION_MOVE_OUT = 700;
  const DURATION_END_PAUSE = 300;
  const DURATION_PRESS_IN = 100;
  const DURATION_HOLD = 300;
  const DURATION_PRESS_OUT = 100;

  const DELAY_APP_SWITCH = 0.5;
  const ANIM_DURATION_APP_SWITCH = 0.3;
  const ANIM_DURATION_RIPPLE = 0.5;

  const {hiddenDivs} = useMetaTimeline({
    // debug: true,
    disabled: viewPrefsCtx.noAutoAnimations,
    points: [
      {
        handler(dir) {},
        position: DURATION_MOVE_IN + DURATION_PRESS_IN + DURATION_HOLD,
        setupTimeline(dir, tl) {
          if (dir === 'forward') {
            tl.fromTo(
              '.Press-ripple',
              {
                opacity: 1,
                scale: 0,
              },
              {
                duration: ANIM_DURATION_RIPPLE,
                ease: 'circ.in',
                opacity: 0,
                scale: 1,
              },
              0,
            );
          }
        },
        toggleActionsBackward: 'none none none none',
        toggleActionsForward: 'play none none reset',
      },
      {
        handler(dir) {},
        position: DURATION_MOVE_IN + DURATION_PRESS_IN + DURATION_HOLD,
        setupTimeline(dir, tl) {
          if (dir === 'forward') {
            tl.to(
              '.Watch-face',
              {
                duration: ANIM_DURATION_APP_SWITCH * (2 / 3),
                ease: 'circ.in',
                opacity: 0,
                scale: 0.5,
              },
              DELAY_APP_SWITCH,
            );

            tl.to(
              '.Heart-rate-app',
              {
                duration: ANIM_DURATION_APP_SWITCH * (2 / 3),
                ease: 'circ.in',
                // ease: 'none',
                opacity: 1,
                scale: 1,
              },
              DELAY_APP_SWITCH + ANIM_DURATION_APP_SWITCH * (1 / 3),
            );
          }
        },
        toggleActionsBackward: 'none none none none',
        toggleActionsForward: 'play none none reverse',
      },
    ],
    scope: containerRef,
    scrub: 0.5,
    setupGlobalTimeline(tl) {
      tl.fromTo(
        '.Hand',
        {
          xPercent: 20,
          yPercent: 30,
        },
        {
          duration: DURATION_MOVE_IN,
          // ease: 'circ.in',
          xPercent: 0,
          yPercent: 0,
        },
        0,
      );

      tl.to(
        '.Hand-zoom',
        {
          duration: DURATION_PRESS_IN,
          // ease: 'circ.in',
          scale: 0.8,
        },
        DURATION_MOVE_IN,
      );

      tl.to(
        '.Clock',
        {
          duration: DURATION_PRESS_IN / 2,
          // ease: 'circ.in',
          opacity: 1,
        },
        DURATION_MOVE_IN + DURATION_PRESS_IN / 2,
      );

      tl.fromTo(
        '.Minutes',
        {rotate: 0},
        {
          duration: DURATION_HOLD,
          ease: 'none',
          rotate: '360deg',
        },
        DURATION_MOVE_IN + DURATION_PRESS_IN,
      );

      tl.fromTo(
        '.Hours',
        {rotate: '60deg'},
        {
          duration: DURATION_HOLD,
          ease: 'none',
          rotate: '90deg',
        },
        DURATION_MOVE_IN + DURATION_PRESS_IN,
      );

      tl.to(
        '.Clock',
        {
          duration: DURATION_PRESS_IN / 2,
          // ease: 'circ.in',
          opacity: 0,
        },
        DURATION_MOVE_IN + DURATION_PRESS_IN + DURATION_HOLD,
      );

      tl.to(
        '.Hand-zoom',
        {
          duration: DURATION_PRESS_OUT / 2,
          // ease: 'circ.in',
          scale: 1,
        },
        DURATION_MOVE_IN + DURATION_PRESS_IN + DURATION_HOLD,
      );

      tl.to(
        '.Hand',
        {
          duration: DURATION_MOVE_OUT,
          // ease: 'circ.in',
          xPercent: 20,
          yPercent: 30,
        },
        DURATION_MOVE_IN + DURATION_PRESS_IN + DURATION_HOLD,
      );

      tl.to(
        '.Hand',
        {
          duration: DURATION_END_PAUSE,
        },
        DURATION_MOVE_IN +
          DURATION_PRESS_IN +
          DURATION_HOLD +
          DURATION_MOVE_OUT,
      );
    },
    totalDuration:
      DURATION_MOVE_IN +
      DURATION_PRESS_IN +
      DURATION_HOLD +
      DURATION_MOVE_OUT +
      DURATION_END_PAUSE,
    trigger: '.Long-press-section',
  });

  return (
    <div ref={containerRef} style={{gap: 0}}>
      {hiddenDivs}
      <ViewTracker sectionId="section_long_press">
        <section
          className="Long-press-section section-with-image"
          style={{marginTop: 0}}>
          <div className="section-title-cell">
            <h2>A touch away</h2>
            <h3>from full details</h3>
          </div>
          <div className="section-image-cell">
            <div
              className="Watch-container section-image-clip Inline-size-container"
              style={{position: 'relative'}}>
              <div className="Watch-image Abs-fill-parent" />
              <div className="Abs-fill-parent Introduce-img-wf-placeholder Long-press-watch-face Animated-element">
                {/* <img alt="logo" className="Watch-face" src={watchFace} /> */}
                <div className="Abs-fill-parent">
                  <NeuProWatchFace />
                </div>
                <div className="Abs-fill-parent Heart-rate-app Animated-element" />
                <div className="Abs-fill-parent Press-location">
                  <div className="Abs-fill-parent Press-ripple Animated-element" />
                  <div className="Abs-fill-parent Hand-zoom Animated-element">
                    <div className="Abs-fill-parent Hand Animated-element">
                      <div className="Abs-fill-parent Clock Animated-element">
                        <div className="Abs-fill-parent Center" />
                        <div className="Abs-fill-parent Hours Animated-element" />
                        <div className="Abs-fill-parent Minutes Animated-element" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-text-cell">
            <p>
              Long press on any data field to open related Garmin apps for
              in-depth insights.
            </p>
          </div>
        </section>
      </ViewTracker>
    </div>
  );
}
