// import 'intl-pluralrules';
// import moment from 'moment';
import {i18n} from './init_i18n';
import strings from './strings';

export type LangCode = keyof typeof strings.l18nResources;

const fallbacklanguage = 'en';

Object.entries(strings.l18nResources).forEach(([lang, res]) => {
  Object.keys(res).forEach(ns => i18n.removeResourceBundle(lang, ns));
  Object.entries(res).forEach(([ns, bundle]) => {
    i18n.addResourceBundle(lang, ns, bundle);
  });
});

i18n.off('languageChanged');
i18n.on('languageChanged', currentLanguage => {
  const locale = Object.keys(strings.l18nResources).includes(currentLanguage)
    ? currentLanguage
    : fallbacklanguage;

  // const localeKey = locale as keyof typeof strings.momentLocaleConfigs;
  // const momentLocaleConfigs = strings.momentLocaleConfigs;

  // moment.updateLocale(
  //   locale,
  //   localeKey in momentLocaleConfigs
  //     ? strings.momentLocaleConfigs[localeKey]
  //     : null,
  // );
});

export {i18n};
