import React, {MouseEventHandler, ReactElement, ReactNode} from 'react';

import {isAndroid, isIOS} from 'react-device-detect';

import CustomDetails from '../components/CustomDetails';
import ViewTracker from '../components/ViewTracker';
import {NEU_PRO_APP_ID} from '../neu_pro/NeuProInfo';
import useNavigateToConnectIq from '../utils/useNavigateToConnectIq';
import NeuProWatchFace from '../watch_face/NeuPro';
import './css/SectionFaq.css';
import './css/SectionIntroduce.css';

type Props = {};

export default function SectionFaq({}: Props): ReactElement {
  const navigateToConnectIq = useNavigateToConnectIq({
    appId: NEU_PRO_APP_ID,
    elementName: 'faq_link',
  });

  const linkClickHandler: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault(); // Prevent navigation to href="#"
    navigateToConnectIq();
  };

  let platformAppsListItems: ReactNode;

  if (isIOS) {
    platformAppsListItems = (
      <>
        <li>The latest version of the Garmin Connect iOS app.</li>
        <li>The latest version of the Connect IQ iOS app.</li>
      </>
    );
  } else if (isAndroid) {
    platformAppsListItems = (
      <>
        <li>The latest version of the Garmin Connect Android app.</li>
        <li>The latest version of the Connect IQ Android app.</li>
      </>
    );
  } else {
    platformAppsListItems = (
      <>
        <li>The latest version of the Garmin Express app.</li>
      </>
    );
  }

  return (
    <ViewTracker sectionId="section_faq">
      <section className="section-with-image Section-faq">
        <div className="section-title-cell">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="section-image-cell">
          <div
            className="Watch-container section-image-clip"
            style={{position: 'relative'}}>
            <div className="Watch-image Abs-fill-parent" />
            <div className="Abs-fill-parent Introduce-img-wf-placeholder">
              <NeuProWatchFace />
            </div>
          </div>
        </div>
        {/* <div className="custom-details-smaller-gap"> */}
        <div className="section-text-cell">
          <div>
            <CustomDetails title="Is my device compatible?">
              <p style={{marginTop: 0}}>
                See the list of compatible devices below:
              </p>
              <ul className="expandable-list">
                <li>Approach® S70 (42mm)</li>
                <li>Approach® S70 (47mm)</li>
                <li>D2™ Mach 1</li>
                <li>D2™ Mach 1 Pro</li>
                <li>Descent™ MK3 (43mm)</li>
                <li>Descent™ MK3 (51mm)</li>
                <li>Enduro™ 2</li>
                <li>Enduro™ 3</li>
                <li>Forerunner® 165</li>
                <li>Forerunner® 165 Music</li>
                <li>Forerunner® 255</li>
                <li>Forerunner® 255 Music</li>
                <li>Forerunner® 255S</li>
                <li>Forerunner® 255S Music</li>
                <li>Forerunner® 265</li>
                <li>Forerunner® 265S</li>
                <li>Forerunner® 955 Dual Power</li>
                <li>Forerunner® 965</li>
                <li>MARQ® (Gen 2)</li>
                <li>
                  MARQ® (Gen 2), MARQ® Carbon Collection, MARQ® Adventurer
                  (Gen 2) - Damascus Steel Edition
                </li>
                <li>MARQ® Commander (Gen 2) – Carbon Edition</li>
                <li>MARQ™ Aviator (Gen 2)</li>
                <li>Mercedes-Benz Venu® 2</li>
                <li>Mercedes-Benz Venu® 2S</li>
                <li>Venu® 2</li>
                <li>Venu® 2 Plus</li>
                <li>Venu® 2S</li>
                <li>Venu® 3</li>
                <li>Venu® 3S</li>
                <li>epix™ (Gen 2)</li>
                <li>epix™ Pro (42mm)</li>
                <li>epix™ Pro (47mm), quatix® 7 Pro</li>
                <li>epix™ Pro (51mm)</li>
                <li>fēnix® 7</li>
                <li>fēnix® 7 Pro</li>
                <li>fēnix® 7 Pro – Solar Edition (No Wi-Fi®)</li>
                <li>fēnix® 7S</li>
                <li>fēnix® 7S Pro</li>
                <li>fēnix® 7X</li>
                <li>fēnix® 7X Pro</li>
                <li>fēnix® 7X Pro No Wi-Fi</li>
                <li>fēnix® 8 AMOLED (43mm)</li>
                <li>fēnix® 8 AMOLED (47mm/51mm)</li>
                <li>fēnix® 8 Solar (47mm)</li>
                <li>fēnix® 8 Solar (51mm), fēnix® 8 Dual Power (51mm)</li>
                <li>fēnix® E</li>
                <li>tactix® 7</li>
                <li>tactix® 7 – AMOLED Edition</li>
                <li>vívoactive® 5</li>
              </ul>
            </CustomDetails>
          </div>
          <div>
            <CustomDetails title="Why use Garmin Pay?">
              <ul style={{marginTop: 0}}>
                <li>
                  <b>No payments outside of Garmin Connect IQ Store.</b>{' '}
                  Purchases and refunds go directly through Garmin.No more
                  hassle with activation codes!{' '}
                </li>
                <li>
                  <b>Works with almost any card,</b> even if they’re not
                  supported for contactless payments on your watch.
                </li>
                <li>
                  <b>Easy, like any other online purchase.</b> Just enter your
                  card details at checkout.
                </li>
              </ul>
            </CustomDetails>
          </div>
          <div>
            <CustomDetails title="How to pay with Garmin Pay">
              <ol style={{marginTop: 0}}>
                <li>Make sure you have:</li>
                <ul>
                  {platformAppsListItems}
                  <li>A compatible Garmin device.</li>
                  <li>
                    A credit or debit card from Mastercard, Visa, Discover,
                    American Express or another major payment network.
                  </li>
                </ul>
                <li>
                  Go to the{' '}
                  <a href="#" onClick={linkClickHandler}>
                    Neu Pro page on Connect IQ
                  </a>
                  .
                </li>
                <li>Tap the Price button in Connect IQ.</li>
                <li>Complete the online purchase as usual.</li>
              </ol>
              <p>
                For more information{' '}
                <a href="https://support.garmin.com/en-GB/?faq=tAsPhqUXOo2L3guHjLpJa7">
                  see the instructions from Garmin
                </a>
              </p>
            </CustomDetails>
          </div>
        </div>
        {/* </div> */}
      </section>
    </ViewTracker>
  );
}
