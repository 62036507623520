/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { ConsentContextProvider } from './utils/ConsentContext';
import { CountryContextProvider } from './utils/CountryContext';
import { ConnectIqVisitContextProvider } from './utils/ConnectIqVisitContext';
import { IntersectionContextProvider } from './utils/IntersectionContext';
import { ConnectIqNavDialogContextProvider } from './components/ConnectIqNavDialog';
import { ViewPreferencesContextProvider } from './utils/ViewPreferencesContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConsentContextProvider>
      <CountryContextProvider>
        <ConnectIqVisitContextProvider>
          <IntersectionContextProvider>
            <ConnectIqNavDialogContextProvider>
              <ViewPreferencesContextProvider>
                <HelmetProvider>
                  <App />
                </HelmetProvider>
              </ViewPreferencesContextProvider>
            </ConnectIqNavDialogContextProvider>
          </IntersectionContextProvider>
        </ConnectIqVisitContextProvider>
      </CountryContextProvider>
    </ConsentContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
