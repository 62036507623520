import {i18n} from '../i18n';
import {
  TextFieldLength,
  formatFloat_0p01_to_999k,
  formatNumber_0_to_999k,
  formatNumeric_days_1_to_999,
  formatNumeric_degrees_neg999_to_999,
  formatNumeric_hours_1_to_100,
  formatNumeric_percents_1_to_100,
  formatRange,
} from './Formatting';

export type DataFieldType =
  | 'month_monthday'
  | 'weekday_monthday'
  | 'battery_days'
  | 'battery_percentage'
  | 'solar_input'
  | 'heart_rate'
  | 'steps'
  | 'distance_walked'
  | 'calories'
  | 'floors_climbed'
  | 'current_temperature'
  | 'min_max_temperature'
  | 'sunrise_sunset'
  | 'altitude'
  | 'notification_count'
  | 'calendar_event'
  | 'body_battery'
  | 'stress'
  | 'intensity_minutes'
  | 'recovery_time'
  | 'weekly_run_distance'
  | 'weekly_bike_distance'
  | 'vo2_max_run'
  | 'vo2_max_bike'
  | 'pulse_ox';

export interface IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string;
  useSmallFontIfLR?: boolean;
}

export class AltitudeDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(230, length);
  }
}

export class BatteryDaysDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_days_1_to_999(9, langOverride);
  }
}

export class BatteryPercentageDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_percents_1_to_100(53, langOverride);
  }
}

export class BodyBatteryDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_percents_1_to_100(78, langOverride);
  }
}

export class CalendarEventDataField implements IDataField {
  useSmallFontIfLR = true;
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return '11:00';
  }
}

export class CaloriesDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(1754, length);
  }
}

export class MonthDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    const date = new Date();
    const idx = date.getMonth();
    return i18n
      .t(
        `months:month${(idx + 1).toFixed(0).padStart(2, '0')}_${length}`,
        langOverride ? {lng: langOverride} : undefined,
      )
      .toLocaleUpperCase();
  }
}

export class MonthDayDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    const date = new Date();
    return date.getDate().toString();
  }
}

export class WeekDayDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    const date = new Date();
    const idx = date.getDay();
    return i18n
      .t(
        `weekdays:day${(idx + 1).toFixed(0)}_${length}`,
        langOverride ? {lng: langOverride} : undefined,
      )
      .toLocaleUpperCase();
  }
}

export class DistanceWalkedDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatFloat_0p01_to_999k(7126, length);
  }
}

export class FloorsClimbedDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(12, length);
  }
}

export class HeartRateDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(59, length);
  }
}

export class IntensityMinutesDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(126, length);
  }
}

export class NotificationCountDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(2, length);
  }
}

export class PulseOxDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_percents_1_to_100(98, langOverride);
  }
}

export class RecoveryTimeDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_hours_1_to_100(15, length, langOverride);
  }
}

export class SolarInputDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_percents_1_to_100(78, langOverride);
  }
}

export class StepsDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(11426, length);
  }
}

export class StressDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return '24';
  }
}

export class SunriseSunsetDataField implements IDataField {
  useSmallFontIfLR = true;
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return '7:24';
  }
}

export class Vo2MaxBikeDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(52, length);
  }
}

export class Vo2MaxRunDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumber_0_to_999k(51, length);
  }
}

export class CurrentTemperatureDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatNumeric_degrees_neg999_to_999(23, langOverride);
  }
}

export class MinMaxTemperatureDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatRange(
      formatNumeric_degrees_neg999_to_999(15, langOverride),
      formatNumeric_degrees_neg999_to_999(24, langOverride),
      langOverride,
    );
  }
}

export class WeeklyBikeDistanceDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatFloat_0p01_to_999k(25.345, length);
  }
}

export class WeeklyRunDistanceDataField implements IDataField {
  getValueAsText(length: TextFieldLength, langOverride?: string): string {
    return formatFloat_0p01_to_999k(11.644, length);
  }
}
