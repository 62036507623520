import React, {ReactElement} from 'react';

import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';

import {NEU_PRO_KIEZEL_PAY_APP_ID} from '../neu_pro/NeuProInfo';
import OpenConnectIqButton from './OpenConnectIqButton';
import {usePrice} from './PriceUtils';

export default function ScreenHeader(): ReactElement {
  const price = usePrice();

  useGSAP(() => {
    const scrollTrigger: ScrollTrigger.Vars = {
      end: `bottom top`,
      // markers: true,
      scrub: true,
      start: 'bottom center',
      trigger: 'section:nth-child(1)',
    };

    let tl = gsap.timeline({scrollTrigger});

    tl.fromTo('header', {yPercent: -150}, {ease: 'circ.out', yPercent: 0}, 0);
  });

  return (
    <header>
      <span>
        <b>Neu Pro</b>
      </span>
      <OpenConnectIqButton
        appId={NEU_PRO_KIEZEL_PAY_APP_ID}
        elementName="header_button"
        title="Try Free Now"
        // title={`Get for ${price}`}
      />
    </header>
  );
}
