/* eslint-disable prettier/prettier */
import * as ar from './ar.json';
import * as bg from './bg.json';
import * as cz from './cz.json';
import * as en from './en.json';
import * as es from './es.json';
import * as fr from './fr.json';
import * as gr from './gr.json';
import * as he from './he.json';
import * as kr from './kr.json';
import * as th from './th.json';

// TODO: normalise the lang codes
import * as da from './dan.json';
import * as de from './deu.json';
import * as hr from './hrv.json';
import * as nl from './dut.json';
import * as et from './est.json';
import * as fi from './fin.json';
import * as hu from './hun.json';
import * as id from './ind.json';
import * as it from './ita.json';
import * as ja from './jpn.json';
import * as lv from './lav.json';
import * as lt from './lit.json';
import * as nb from './nob.json';
import * as pl from './pol.json';
import * as pt from './por.json';
import * as ro from './ron.json';
import * as ru from './rus.json';
import * as sk from './slo.json';
import * as sl from './slv.json';
import * as sv from './swe.json';
import * as tr from './tur.json';
import * as uk from './ukr.json';
import * as vi from './vie.json';
import * as ms from './zsm.json';
import * as zh from './zht.json';

export default {
  l18nResources: {
    ar,
    bg,
    cz,
    da,
    de,
    en,
    es,
    et,
    fi,
    fr,
    gr,
    he,
    hr,
    hu,
    id,
    it,
    ja,
    kr,
    lt,
    lv,
    ms,
    nb,
    nl,
    pl,
    pt,
    ro,
    ru,
    sk,
    sl,
    sv,
    th,
    tr,
    uk,
    vi,
    zh,
  },
};
