const supportedLanguages = {
  ar: '',
  bg: '',
  cz: '',
  da: '',
  de: '',
  en: '',
  es: '',
  et: '',
  fi: '',
  fr: '',
  gr: '',
  he: '',
  hr: '',
  hu: '',
  id: '',
  it: '',
  ja: '',
  kr: '',
  lt: '',
  lv: '',
  ms: '',
  nb: '',
  nl: '',
  pl: '',
  pt: '',
  ro: '',
  ru: '',
  sk: '',
  sl: '',
  sv: '',
  th: '',
  tr: '',
  uk: '',
  vi: '',
  zh: '',
};
export default supportedLanguages;
