import React, {ReactElement} from 'react';

import {useOpenConnectIqNavDialog} from './ConnectIqNavDialog';

type Props = {
  appId: string;
  elementName: string;
  title: string;
};

export default function OpenConnectIqButton({
  appId,
  elementName,
  title,
}: Props): ReactElement {
  const openConnectIqNavDialog = useOpenConnectIqNavDialog(appId, elementName);

  const clickHandler = () => {
    openConnectIqNavDialog();
  };

  return <button onClick={clickHandler}>{title}</button>;
}
