import React, {ReactNode, useContext, useRef} from 'react';

import {useInView} from 'react-intersection-observer';

import {logAnalyticsEvent} from '../utils/AnalyticsUtils';
import {IntersectionContext} from '../utils/IntersectionContext';

export type SectionId =
  | 'section_intro'
  | 'section_style'
  | 'section_mip_amoled'
  | 'section_data_fields'
  | 'section_long_press'
  | 'section_seconds'
  | 'section_languages'
  | 'section_install'
  | 'section_faq';

type Props = {
  children: ReactNode;
  sectionId: SectionId;
};

export default function ViewTracker({children, sectionId}: Props) {
  const ctx = useContext(IntersectionContext);

  const seenBeforeRef = useRef(false);

  const {entry, inView, ref} = useInView({
    onChange(inView, entry) {
      if (inView) {
        ctx.reportSectionVisible(sectionId);

        if (!seenBeforeRef.current) {
          logAnalyticsEvent('view_section', {
            sectionId,
          });

          seenBeforeRef.current = true;
        }
      }
    },
    rootMargin: '0px 0px -60% 0px', // rootMargin: top, right, bottom, left
    threshold: 0.001,
  });

  return <div ref={ref}>{children}</div>;
}
